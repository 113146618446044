<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :sst="true"
        :data="wills"
        @sort="handleSort"
        @search="search"
        @selected="$router.push({ name: 'will', params: { id: $event.id } })"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <!-- STATUS -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ status }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="setLimit('All', 'all')">
                        <span>All </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        divider
                        @click="setLimit('Created - To Pay', 'to_pay')"
                    >
                        <span>Created - To Pay</span>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        @click="setLimit('Paid - To Check', 'to_check')"
                    >
                        <span>Paid - To Check</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('On Hold', 'on_hold')"
                    >
                        <span>On Hold</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('Contact - Admin', 'to_contact_admin')"
                    >
                        <span>Contact - Admin</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="
                            setLimit(
                                'Contact - Estate Planner',
                                'to_contact_estate'
                            )
                        "
                    >
                        <span>Contact - Estate Planner</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('Checked - To Approve', 'to_approve')"
                    >
                        <span>Checked - To Approve</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('Approved - To Print', 'to_print')"
                    >
                        <span>Approved - To Print</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        divider
                        @click="setLimit('End - Complete', 'end_complete')"
                    >
                        <span>End - Complete</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="setLimit('End - In Storage', 'in_storage')"
                    >
                        <span>End - In Storage</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="
                            setLimit('End - Handover to DM', 'end_handover')
                        "
                    >
                        <span>End - Handover to DM</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="
                            setLimit('End - No Response', 'end_no_response')
                        "
                    >
                        <span>End - No Response</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="setLimit('End - Lost', 'end_lost')"
                    >
                        <span>End - Lost</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="
                            setLimit(
                                'End - Duplicate Submission',
                                'end_duplicate'
                            )
                        "
                    >
                        <span>End - Duplicate Submission</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="setLimit('End - Deceased', 'end_deceased')"
                    >
                        <span>End - Deceased</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ itemsPerPage }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th sort-key="user__personaldetails__full_name">Name</vs-th>
            <vs-th sort-key="">Status</vs-th>
            <vs-th sort-key="uploaded_at">Date Created</vs-th>
            <vs-th sort-key="user__email">Email</vs-th>
            <vs-th sort-key="coupon_used__broker">Introducer/Coupon</vs-th>
            <vs-th sort-key="payment_details__will_amount">Paid Amount</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="will" :key="index" v-for="(will, index) in data">
                    <vs-td>
                        <span v-if="will.user && will.user.details">{{
                            will.user.details.full_name
                        }}</span>
                        <span v-else>NO DATA</span>
                    </vs-td>

                    <vs-td>
                        <vs-chip> {{ statusLabel(will) }}</vs-chip>
                    </vs-td>

                    <vs-td>
                        <vx-tooltip
                            color="black"
                            title="Paid Created"
                            :text="formatDateAndTime(will.uploaded_at)"
                            position="bottom"
                        >
                            <span class="flex items-center px-2 py-1 rounded">
                                {{ timeDifference(will.uploaded_at) }}
                            </span>
                        </vx-tooltip>
                    </vs-td>

                    <vs-td v-if="will.user">
                        {{ will.user.email }}
                    </vs-td>

                    <vs-td>
                        {{ introducer(will.coupon_used) }}<br />{{
                            couponUsed(will.coupon_used)
                        }}
                    </vs-td>

                    <vs-th>
                        <div
                            v-if="
                                will.payment_details &&
                                will.payment_details.will_amount
                            "
                            class="mr-3 mt-3"
                        >
                            <vx-tooltip
                                :text="`Paid £${
                                    will.payment_details.will_amount / 100
                                } for Will`"
                                position="bottom"
                            >
                                <feather-icon
                                    icon="DollarSignIcon"
                                ></feather-icon>
                            </vx-tooltip>
                        </div>
                        <div
                            v-if="
                                will.payment_details &&
                                will.payment_details.printing_amount
                            "
                            class="mr-3 mt-3"
                        >
                            <vx-tooltip
                                text="Paid for Printing"
                                position="bottom"
                            >
                                <feather-icon icon="PrinterIcon"></feather-icon>
                            </vx-tooltip>
                        </div>
                        <div
                            v-if="
                                will.subscription_details &&
                                will.subscription_details.active
                            "
                            class="mr-3 mt-3"
                        >
                            <vx-tooltip
                                text="Active Subscription"
                                position="bottom"
                            >
                                <feather-icon icon="AwardIcon"></feather-icon>
                            </vx-tooltip>
                        </div>

                        <div v-if="will.update" class="mr-3 mt-3">
                            <vx-tooltip text="Update Will" position="bottom">
                                <feather-icon
                                    icon="AlertCircleIcon"
                                ></feather-icon>
                            </vx-tooltip>
                        </div>
                    </vs-th>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'WillsAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage'],
    computed: {
        wills() {
            return this.data.data
        },
    },
    data() {
        return {
            selected: null,
            searchQuery: '',
            status: 'All',
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        statusLabel(data) {
            if (data.end_deceased) return 'End - Deceased'
            if (data.in_storage) return 'End - In Storage'
            if (data.end_lost) return 'End - Lost'
            if (data.end_no_response) return 'End - No Response'
            if (data.end_handover) return 'End - Handover to DM'
            if (data.end_duplicate) return 'End - Duplicate Submission'
            if (
                (data.printing_required && data.printed && data.checked) ||
                (!data.printing_required && data.checked)
            )
                return 'End Complete'
            if (data.checked && data.printing_required && !data.printed)
                return 'Approved - To Print'
            if (data.checked_pre_final) return 'Checked - To Approve'
            if (data.checked_contact_client_advice)
                return 'Contact - Estate Planner'
            if (data.checked_contact_client_issue) return 'Contact - Admin'
            if (data.on_hold) return 'On Hold'
            if (data.paid) return 'Paid - To Check'
            return 'Created - To Pay'
        },
        handleSort(key, active) {
            this.$emit('sort', key, active)
        },
    },
}
</script>
